<template>
<main class="main" role="main">
    <div class="main-container">

        <div class="timezone-container">
            <div class="heading">
                Clock.zone
            </div>
                <!-- 300x250 -->
                <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                <div id="waldo-tag-12623"></div>
                </div> -->
                <p class="pt-20">
                Welcome, let me introduce the <a href="">clock.zone.</a> My main goal was to build a website where you can
                find <b>real exact time.</b> Why? Most of the applications or websites are showing time from your device
                clock. Sometimes it may be late or ahead (sometimes just few seconds, sometime minutes). This clock shows
                time from our dedicated server synchronised with atomic clock.<br>
                More details and <b>demonstration video</b> you can find on <router-link :to="{path:'/about'}" >this page.</router-link><br><br>
                </p>
                <!-- <div style="display: flex; flex-grow: 1; justify-content: center">
                <div id="waldo-tag-12334"></div>
                </div> -->
                <p>
                If you find time zone (by region and city or by country), exact <b>local time clock</b> will be visible on
                that page. You can set this clock to run in background or place it on your website as widget.<br><br>

                Thank you for using clock.zone!<br><br>

                <b>Igor Gaspar, PhD student</b><br>
            </p>
        
        </div>
        <div class="clock-container">
            <h1 class="heading">
                Exact time clock for your time zone
            </h1>
             <!-- 728x90/320x50 -->
            <!-- <div id="waldo-tag-12625"></div> -->
            <div class="clock-side">
                <div class="clock-inner">
                    <div id="MyClockDisplay" class="clocks" onload="showTime()">
                          <span id="hh"></span> 
                          <span>:</span>
                          <span id="mm"></span>
                          <span>:</span>
                          <span id="ss"></span>
                          <span>:</span>
                          <span id="ms"></span>
                          
                          <span style="font-size:10px;margin-left:20px" id="session"></span>

                    </div>
                    <div class="clock-stats">
                        <ul>
                            <li>Your clock offset: <span>{{sync.offset}} s</span> </li>
                            <li>Sync precision: <span>±{{sync.precision}} s</span></li>
                            <li>Sync succ: <span>{{sync.success}}</span></li>
                            <li>Latest sync: <span>{{sync.last}}</span></li>
                            <li>Sync fail: <span>{{sync.fail}}</span></li>
                        </ul>
                    </div>
                </div>
                                
                <div class="clock">
                    <div class="outer-clock-face">
                        <div class="marking marking-one"></div>
                        <div class="marking marking-two"></div>
                        <div class="marking marking-three"></div>
                        <div class="marking marking-four"></div>
                        <div class="inner-clock-face">
                            <div class="hand hour-hand"></div>
                            <div class="hand min-hand"></div>
                            <div class="hand second-hand"></div>
                        </div>
                    </div>
                </div>
          
            </div>
                <!-- 728x90/320x50 -->
                <!-- <div id="waldo-tag-12626"></div> -->
        </div>
    </div>
    <footers></footers>

</main>
</template>

<script>
import axios from 'axios'
import moment from "moment"
import moment_tz from 'moment-timezone'
import footers from '@/views/Footer.vue'

export default {
    components: {footers},
    data() {
        return {
            events: [],
            tz: {},
            file_url: this.$store.state.file_url,
            homePage: [],
            api_url: "https://howmanydaystill.com/_read",
            sync: {
                success: 0,
                fail: 0
            }
            //api_url:"http://localhost:5000/_read",
        }
    },
    watch: {
        '$router.params': {
            handler(newValue, oldValue) {
                console.log(3)
                  setTimeout(d=>{
                 waldo.refreshTag("waldo-tag-12623")
                 waldo.refreshTag("waldo-tag-12334")
                 waldo.refreshTag("waldo-tag-12625")
                 waldo.refreshTag("waldo-tag-12626")
        },1000)
            }
        }
    },
    created() {
        setTimeout(d => {
            this.timeDate()
        }, 500)
        this.syncs()
         document.title = "Exact Time Clock Now (With Seconds, Milliseconds)";
         setTimeout(d=>{
                 waldo.refreshTag("waldo-tag-12334")
                 waldo.refreshTag("waldo-tag-12623")
                 waldo.refreshTag("waldo-tag-12625")
                 waldo.refreshTag("waldo-tag-12626")
        },1000)
    },
    methods: {
        syncs() {
            var current = moment().valueOf();
            axios.get(this.$store.state.api_url + "_/_offset").then(response => {
                        var nows = moment().valueOf();
                        this.sync.precision = (nows - current) / 1000;
                        this.sync.last = moment().format("HH:mm:ss");
                        var nytz = moment_tz.tz(moment.tz.guess());
                        var asstring = nytz.format();
                        var asunixtime = parseInt(nytz.format('X'));
                        var offset = moment_tz.parseZone(asstring).utcOffset();
                        this.sync.offset = offset;
                        this.sync.success++;
                        var t  = this.sync;
                        this.sync = {}
                        this.sync = t
                        //setTimeout(this.syncs(), 1800);
                }).catch(error => {
                  this.sync.error++;
              })
        setTimeout(d=>{
          this.syncs()
        },3000)
    },
    timeDate() {
        var secondHand = document.querySelector('.second-hand');
        var minsHand = document.querySelector('.min-hand');
        var hourHand = document.querySelector('.hour-hand');

        var setDate = () => {
            const now = new Date();

            const seconds = now.getSeconds();
            const secondsDegrees = ((seconds / 60) * 360) + 90;
            secondHand.style.transform = `rotate(${secondsDegrees}deg)`;

            const mins = now.getMinutes();
            const minsDegrees = ((mins / 60) * 360) + ((seconds / 60) * 6) + 90;
            minsHand.style.transform = `rotate(${minsDegrees}deg)`;

            const hour = now.getHours();
            const hourDegrees = ((hour / 12) * 360) + ((mins / 60) * 30) + 90;
            hourHand.style.transform = `rotate(${hourDegrees}deg)`;
        }

        setInterval(setDate, 1000);

        setDate();
        var showTime = () => {
            var date = new Date();
            var h = date.getHours(); // 0 - 23
            var m = date.getMinutes(); // 0 - 59
            var s = date.getSeconds(); // 0 - 59
            var ms = Math.floor(date.getMilliseconds() / 10).toFixed(0); // 0 - 59
            var session = "AM";

            if (h == 0) {
                h = 12;
            }

            if (h > 12) {
                h = h - 12;
                session = "PM";
            }

            h = (h < 10) ? "0" + h : h;
            m = (m < 10) ? "0" + m : m;
            s = (s < 10) ? "0" + s : s;
            if(document.getElementById('hh')){
                document.getElementById('hh').innerText = h;
                document.getElementById('mm').innerText = m;
                document.getElementById('ss').innerText = s;
                document.getElementById('ms').innerText = ms;
                document.getElementById("session").innerText = session
            }   
            

            setTimeout(showTime, 10);

        }

        showTime();
    }
}
}
</script>
